import React from 'react'
import Footer from './footer';

const Price = () => {
  return (
    <div>
     
    <Footer/>
    </div>
  )
}

export default Price;